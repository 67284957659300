import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "",
    component: () => import("../views/App/Home/HomeView"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/App/Home/HomeView"),
  },
  {
    path: "/customer_registration",
    name: "Customer Registration",
    component: () => import("../views/App/Customer/CustomerRegistration"),
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import("../views/App/Loans/PaymentView"),
  },
  {
    path: "/center_recover",
    name: "Center Recover",
    component: () => import("../views/App/Loans/CenterRecover"),
  },
  {
    path: "/reports_my_collection",
    name: "Reports My Collection",
    component: () => import("../views/App/Reports/ReportsMyCollection"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/App/Auth/LoginView"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "@/store";
router.beforeEach((to, from, next) => {
  document.title = "MICROPOS | " + to.name;
  if (to.name !== "Login" && !store.state.user) {
    next({ name: "Login" });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
export default router;
