<template>
  <div class="c">
    <router-link :to="'/'" class="btn btnb w-50">MENU</router-link>
    <button class="btn btnb w-50" @click="goBack">BACK</button>
  </div>
</template>

<script>
export default {
  setup() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.btnb {
  padding: 0.75em;
  font-size: 1em;
  background-color: rgba(63, 63, 63, 0.459);
  border: thin solid #96969670;
  height: 50px;
}
.c {
  border-top: solid thin #79797970;
  height: 50px;
}
</style>