<template>
  <router-view class="routercontent" :key="$route.fullPath"></router-view>
  <BotNav v-if="user"></BotNav>
  <notifications position="bottom right" width="100%" />
</template>

<script>
import axios from "axios";
import store from "@/store";
import BotNav from "./views/layouts/AppComponents/BotNav.vue";
export default {
  setup() {},
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
    user() {
      return store.state.user;
    },
  },
  components: {
    BotNav,
  },
  async beforeCreate() {
    var token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      try {
        const response = await axios.post("verify");
        //  console.log(response.data);
        if (response.data.status == true) {
          this.$store.dispatch("user", response.data.user);

          this.$router.push("/");
        }
      } catch (err) {
        if (err) {
          this.loading = false;
        }
      }
    }
  },
};
</script>

<style>
.routercontent {
  transition: 00.5s;
  overflow: scroll;
  height: calc(100vh - 50px);
}
</style>